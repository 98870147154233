import { Link } from 'react-router-dom';
import {
	FaPhoneAlt,
	FaMapMarkerAlt,
	FaEnvelope,
	FaFacebook,
	FaInstagram,
	FaWhatsapp,
	FaLongArrowAltRight,
} from "react-icons/fa";
import { useState } from 'react';


const Footer = () => {

	const [fullName, setFullName] = useState(null);

	const onChange = e => setFullName(e.target.value);

	return (
		<div className='container-footer'>
			<div className='container-footer-info'>
				<div className='contain-footer-info'>
					<img src={require(`../../images/logojoyitaoscuro.png`)} className='container-logo-footer' />
					<h4 className='footer-subtitle'>Contacto</h4>
					<p className='footer-contact-item'><FaPhoneAlt className='icon-footer' />+54 9 3574-454270</p>
					<p className='footer-contact-item'><FaEnvelope className='icon-footer' />gaunasteel145@gmail.com</p>
					<p className='footer-contact-item'><FaMapMarkerAlt className='icon-footer' />Av Sarmiento 290</p>
				</div>
				<div className='contain-footer-info'>
					<h4 className='footer-subtitle'>Nuestras Redes</h4>
					<div className ='contain-footer-social'>
						<Link to='https://www.facebook.com/lajoyita.ok' className='social-footer-links facebook' style={{textDecoration: 'none'}}><FaFacebook /></Link>
						<Link to='https://www.instagram.com/lajoyitaoro.ok/' className='social-footer-links instagram' style={{textDecoration: 'none'}}><FaInstagram /></Link>
						<Link to='https://wa.me/+543574454270?text=Buenos%20días,%20La%20Joyita,%20quiero%20información%20de%20los%20productos.' className='social-footer-links whatsapp' style={{textDecoration: 'none'}}><FaWhatsapp /></Link>
					</div>
				</div>
				<div className='contain-footer-info footer-info-phone'>
					<h4 className='footer-subtitle'>Enlaces</h4>
					<div className='container-footer-links'>
						<Link to='/' className='footer-links' style={{textDecoration: 'none'}}>Inicio</Link>
						<Link to='/shop' className='footer-links' style={{textDecoration: 'none'}}>Tienda</Link>
						<Link to='/contact' className='footer-links' style={{textDecoration: 'none'}}>Contacto</Link>
						<Link to='/about' className='footer-links' style={{textDecoration: 'none'}}>Sobre Nosotros</Link>
					</div>
				</div>
				<div className='contain-footer-info footer-info-phone'>
					<h4 className='footer-subtitle'>Legal</h4>
					<div className='container-footer-links'>
						<Link to='/purchasing-policies' className='footer-links' style={{textDecoration: 'none'}}>Políticas de Compras</Link>
						<Link to='/cookies-policies' className='footer-links' style={{textDecoration: 'none'}}>Política de Cookies</Link>
						<Link to='/privacy-policies' className='footer-links' style={{textDecoration: 'none'}}>Política de Privacidad</Link>
						<Link to='/frequents-questions' className='footer-links' style={{textDecoration: 'none'}}>Preguntas Frecuentes</Link>
					</div>
				</div>
				<div className='contain-footer-info'>
					<h4 className='footer-subtitle'>Recibe Promociones y Descuentos</h4>
					<div className='promotions-input-link'>
						<input type='text' placeholder='Nombre y Apellido' className='input-promotions' onChange={e => onChange(e)} />
						<Link to={`https://wa.me/+543574454270?text=Buenos%20días,%20mi%20nombre%20es:%20${fullName}%20quiero%20recibir%20las%20promociones%20y%20descuentos.`} className='link-promotions'><FaLongArrowAltRight /></Link>
					</div>
				</div>
			</div>
			<div className='container-footer-rights'>
				<span className='container-rights'>
					La Joyita {new Date().getFullYear()} &copy; Todos los Derechos Reservados | Imágenes by Freepik
				</span>
				<p>Elaborado por <Link to='https://k-ching.com' className='footer-links' style={{textDecoration: 'none'}}>K-Ching</Link></p>
			</div>
		</div>
	);
};

export default Footer;