import Layout from '../../hocs/Layout';
import { FaCircle } from 'react-icons/fa';
import '../../styles/static-pages.css';
import { useEffect } from 'react';


const PurchasingPolicies =({
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container-banner-all policies'>
				<h3>Políticas</h3>
			</div>
			<div className='container'>
				<div className='container-policies'>
					<h2>Política de Compras, Envíos y Devoluciones</h2>
					<p>En La Joyita, nos comprometemos a brindar a nuestros clientes una experiencia de compra segura y transparente. 
					Para garantizar la mejor calidad en nuestros productos y servicio, hemos desarrollado la siguiente política de 
					compras, envíos y devoluciones.
					</p>
					<h3>Compras:</h3>
					<p><FaCircle className='icon-policies' />Todos los productos disponibles en nuestro sitio web están sujetos a 
						disponibilidad de stock.
					</p>
					<p><FaCircle className='icon-policies' />Los precios de los productos están claramente indicados en nuestro sitio 
						web y pueden estar sujetos a cambios sin previo aviso.
					</p>
					<p><FaCircle className='icon-policies' />Los clientes pueden realizar sus compras directamente a través de nuestro 
						sitio web utilizando nuestro sistema de pago seguro mediante Mercado Pago.
					</p>
					<p><FaCircle className='icon-policies' />Aceptamos pagos con tarjetas de crédito, débito y saldo en cuenta de 
						Mercado Pago, entre otros métodos disponibles en la plataforma.
					</p>
					<h3>Envíos:</h3>
					<p><FaCircle className='icon-policies' />Procesamos los pedidos dentro de un plazo de 3 a 5 días hábiles después 
						de recibir el pago.</p>
					<p><FaCircle className='icon-policies' />Los envíos se realizan a través de empresas de transporte de terceros. 
						Las tarifas y tiempos de entrega pueden variar según la ubicación del cliente y el método de envío seleccionado.
					</p>
					<p><FaCircle className='icon-policies' />Una vez despachado el pedido, los clientes recibirán un correo de 
						confirmación con un número de seguimiento para monitorear el estado de su envío.
					</p>
					<p><FaCircle className='icon-policies' />Nos esforzamos por garantizar la entrega oportuna, pero no nos hacemos 
						responsables de retrasos causados por circunstancias fuera de nuestro control, como desastres naturales, 
						problemas logísticos o fuerza mayor.
					</p>
					<h3>Devoluciones y Cambios:</h3>
					<p><FaCircle className='icon-policies' />Aceptamos devoluciones y cambios dentro de los 30 días posteriores a la 
						compra, siempre que el producto esté en perfectas condiciones, sin uso y con su empaque original.
					</p>
					<p><FaCircle className='icon-policies' />Por razones de higiene y seguridad, no aceptamos devoluciones de aros, 
						piercings u otros accesorios de uso corporal.
					</p>
					<p><FaCircle className='icon-policies' />Los costos de envío por devoluciones o cambios serán responsabilidad del 
						cliente, salvo en casos de productos defectuosos o errores en el pedido.
					</p>
					<h3>Excepciones:</h3>
					<p>Algunos productos pueden estar excluidos de nuestra política de devoluciones y cambios por ser personalizados 
						o de edición limitada. Estas excepciones estarán claramente indicadas en la descripción del producto.
					</p>
					<p>En La Joyita, nos comprometemos a ofrecer un servicio de calidad y a resolver cualquier inconveniente de manera 
						justa y eficiente. Si tienes alguna consulta, no dudes en contactarnos. ¡Gracias por elegirnos para embellecer 
						tus momentos especiales!
					</p>
				</div>
			</div>
		</Layout>
	)
};

export default PurchasingPolicies;